import React, { useEffect } from "react";
import axios from "axios"
import { useStateMachine } from 'little-state-machine'

import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import { updateUserDetails } from '../components/helpers/updateUserDetails'
import { isBrowser } from "../components/helpers/helpers"
import Transition from "./Transition";

import "../sass/index.scss"

//Note that we need to pass location to our functional component  so we have access to it down there in <Transition/>
const Layout = ({ children, location, pageContext }) => {

  const { actions, state } = useStateMachine({ updateUserDetails });

  const getData = async () => {
    // This method fails if an adblocker is running.
    // If uncaught, it leaves things in an unstable state when debugging
    try {
      const res = await axios.get('https://geolocation-db.com/json/')
      return await res.data;
    } catch (error) {
      return { IPv4: '0.0.0.0' }
    }
  }

  useEffect(() => {
    if (isBrowser()) getData().then(data => actions.updateUserDetails({ipAddress: data.IPv4}))
  }, [actions]);

	const { noComponentWrapping = false } = pageContext

	console.log(location.pathname)

  return noComponentWrapping ?
		<>
		  {children}
    </> :
		<>
			<Header />
			<Transition location={location}>{children}</Transition>
			<Footer location={location} />
		</>
};

export default Layout;
