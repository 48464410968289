const ThemeDefault = {
  /* FONT SIZES */
  font__2: "clamp(1rem, calc(1rem + ((1vw - 0.48rem) * 0.3922)), 1.4rem)",
  font__1: "clamp(1.2rem, calc(1.2rem + ((1vw - 0.48rem) * 0.2941)), 1.5rem)",
  font_0: "clamp(1.4rem, calc(1.4rem + ((1vw - 0.48rem) * 0.1961)), 1.6rem)",
  font_1: "clamp(1.6rem, calc(1.6rem + ((1vw - 0.48rem) * 0.1961)), 1.8rem)",
  font_2: "clamp(1.7rem, calc(1.7rem + ((1vw - 0.48rem) * 0.7813)), 2.1rem)",
  font_3: "clamp(1.8rem, calc(1.8rem + ((1vw - 0.48rem) * 1.0938)), 2.5rem)",
  font_4: "clamp(2rem, calc(2rem + ((1vw - 0.48rem) * 1.25)), 2.8rem)",
  font_5: "clamp(2.2rem, calc(2.4rem + ((1vw - 0.48rem) * 1.5625)), 2.8rem)",
  font_6: "clamp(2.8rem, calc(2.8rem + ((1vw - 0.48rem) * 2.0312)), 3.2rem)",
  font_7: "clamp(3.2rem, calc(3.5rem + ((1vw - 0.48rem) * 2.0312)), 3.8rem)",
  fontPrimary: "Avenir, open-sans, sans-serif",
  fontSecondary: "'Libre', Garamond, 'Times New Roman', serif", 
  /* SPACING */
  responsivePadding: "clamp(3.2rem,1.28rem + 4vw, 6.4rem) 24px",
  /* COLOURS */
  white: "#ffffff",
  offWhite: "#F0F0F0",
  black: "#161414",
  blackHover: "#292424",
  blackAbsolute: "#000",
  primary: "#5c8eff",
  primaryDark: "#4179c9",
  primaryLight: "#a7cafc",
  secondary: "#ff9e1b",
  secondaryDark: "#dd8114",
  secondaryLight: "#ffcb92",
  tertiary: "#8edd65",
  tertiaryDark: "#77b552",
  tertiaryLight: "#c1f7a1",
  blue: "#5c8eff",
  blueDark: "#4179c9",
  blueLight: "#a7cafc",
  grey: "#d0d0ce",
  greyDark: "#a5a5a5",
  greyLight: "#eaeaea",
  menuHightlight: "#fae053",
  menuHightlightDark: "#e5c642",
  menuHightlightLight: "#fff1b6",
  yellow: "#fae053",
  yellowDark: "#e5c642",
  yellowLight: "#fff1b6",
  pink: "#F1A7DC",
  pinkDark: "#c073aa",
  pinkLight: "#f5cce9",
}

export default ThemeDefault