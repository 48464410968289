export const signUpObject = () => {
  return {
    signUpDetails: {
      email: '',
      fullName: '',
      contactNumber: '',
      password: '',
      bearerToken: '',
    }
  }
}