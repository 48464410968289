import React, { useState } from "react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import FooterComponent from "./FooterStyles"
import { useSiteMenuData } from "../hooks/useSiteMenuData";
import { useRelative } from "../hooks/useRelative";

import { getCurrentYear } from "../helpers/helpers"

import Facebook from '../../assets/images/icons/social-facebook.svg'
import Instagram from '../../assets/images/icons/social-instagram.svg'
import LinkedIn from '../../assets/images/icons/social-linkedin.svg'
import Twitter from '../../assets/images/icons/social-twitter.svg'

import LogoDial from "../../assets/images/logo/logo-dial-1.svg"
import Logo from "../../assets/images/logo/logo-text-alt.svg"
import Masonry from "react-masonry-css";
import { useStateMachine } from "little-state-machine";
import axios from "axios";
import Cookies from 'js-cookie';
import { updateUserDetails } from '../helpers/updateUserDetails'
import LoadedFormButton from "../elements/LoadedFormButton";

const breakpointColumnsObj = {
  default: 3,
  700: 2,
  500: 1
};

const Footer = ({location}) => {
  
  const menuData = useSiteMenuData();
  const url = typeof window !== 'undefined' ? window.location.href : '';
  
  const [email, setEmail] = useState("")
  const [formMessage, setformMessage] = useState("Form sent. Thank you!")
  
  const portalId = "20809464"
  const formId = "70f76908-fe68-42fe-b379-1e7f85ca2dd2"
  //console.log(formId);
  const { state } = useStateMachine({ updateUserDetails });

  const [isLoading, setIsLoading] = useState(false)
  const [isSent, setIsSent] = useState(false)

	const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    const config = { // important!
      headers: {
        'Content-Type': 'application/json',
      }
    }

    const response = await axios.post(
        `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
        {
          portalId,
          formId,
          context: {
            ipAddress: state.userDetails.ipAddress,
            hutk: Cookies.get('hubspotutk'),
            pageUri: url,
          },
          fields: [
            {
              name: "email",
              value: email,
            },
          ],
          config
        }
      )
      .then(function(response) {
        //console.log(response)
        const message = response.data.inlineMessage
        setformMessage(message)
        setIsLoading(false)
        setIsSent(true)
      })
      
      //console.log(response);

  }

  return (
    <FooterComponent cssClass="section-bottom">
				{ ( !location.pathname.includes('/login') && !location.pathname.includes('/sign-up') ) && 
					<div className="inner-div footer__inner" style={{paddingTop: "16px"}}>
						<Link to="/" className="footer__logo" style={{ width: "100%", textAlign: "left", margin: "8px auto 16px 16px", display: "block"}}>
							<LogoDial className="footer__logoDial" style={{width: "42px"}}/>
							<Logo className="footer__logoText" style={{width: "110px"}} />
						</Link>
					</div>
				}
					<div className="footer__contents">
				{ ( !location.pathname.includes('/login') && !location.pathname.includes('/sign-up') ) && 
					
						<div className="align-items-flex-end c-columns-4 c-columns-gap-xs c-columns-l-1">
							
							<div className="col-2 c-col-span-3 footer-nav-wrap">
								<Masonry
										breakpointCols={breakpointColumnsObj}
										className="my-masonry-grid"
										columnClassName="my-masonry-grid_column"
									>
									
										{menuData.menus.nodes.filter((m)=>m.slug.includes("footer-menu")).map((menu, index) => 
											<div className="inner-col-1" key={`footer-inner-col1-${index}`}>
												<nav className="footer-menu col-1">
													{menu.menuItems.nodes.map(
														(item, index) =>
															(
																<MenuItem key={`footer-nav-col1-${index}`} cssClass={item.cssClasses} {...item} />
															)
													)}
												</nav>
											</div>
										)}
									
									</Masonry>
							</div>
							
							<div className="col-1 c-relative mh-100" style={{ display: 'flex', flexDirection: "column",justifyContent: "space-between" }}>
								<div className="group">	
									<div><strong>Subscribe</strong></div>
									<div style={{marginBottom: '8px'}}>Join our newsletter to stay up to date on features and releases.</div>
									<form onSubmit={handleSubmit} id={formId}>
										<input 
											type="email"
											name="email"
											placeholder="Email"
											required
											value={email}
											onChange={e => setEmail(e.target.value)}
											style={{width: "100%", padding: '8px', marginBottom: '8px'}}
											/>
										<LoadedFormButton style={{width: "100%"}} type="submit" className="footer-submit">{isLoading ? "Sending…" : "Send Now"}</LoadedFormButton>
									</form>
									<div className="disclaimer">By subscribing you agree with our Privacy Policy and provide consent to receive updtes from our company</div>
								</div>
								<div className="inner-col-3">
									<div className="socials">
										Find us on
									</div>
									<div className="socialWrapper">
										<a href={menuData.socialFields.facebook} target="_blank"  rel="noreferrer" aria-label="Facebook"><div style={{width: '40px'}} className="social facebook"><Facebook /></div></a>
										<a href={menuData.socialFields.instagram} target="_blank"  rel="noreferrer" aria-label="Instagram"><div style={{width: '40px'}} className="social insta"><Instagram /></div></a>
										<a href={menuData.socialFields.linkedin} target="_blank"  rel="noreferrer" aria-label="LinkedIn"><div style={{width: '40px'}} className="social linkedin"><LinkedIn /></div></a>
										<a href={`https://www.twitter.com/${menuData.socialFields.twitter.replace('@', '')}`} aria-label="Twitter Icon" target="_blank" rel="noreferrer"><div style={{width: '40px'}} className="social linkedin"><Twitter /></div></a>
									</div>
								</div>
							</div>

						</div>
				}

				<div className="copyright c-columns-2">
					<div className="copy">Copyright ©{getCurrentYear()} Loaded Reports</div>
					<div className="site-credit"><a href="https://rdstudios.co.nz" target="_blank"  rel="noreferrer" >Site Credit</a></div>
				</div>
      </div>
    </FooterComponent>
  )
}

export default Footer

const MenuItem = ({ label, path, cssClass }) => {
  const relativeLink = useRelative(path)
  // console.log('url', path, relativeLink);
  return relativeLink.includes('#') ? <AnchorLink to={relativeLink} className={`footerNav__link ${cssClass.length > 0 ? cssClass[0] : ''}`}>{label}</AnchorLink> : <Link to={relativeLink} className={`footerNav__link ${cssClass.length > 0 ? cssClass[0] : ''}`}>{label}</Link>
}