/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react'
// import SimpleReactLightbox from 'simple-react-lightbox-pro'
import { StateMachineProvider, createStore } from 'little-state-machine';

import { signUpObject } from './src/components/helpers/SignUpObject'
import { userObject } from './src/components/helpers/UserObject'

const signUp = signUpObject();
const ipAddress = userObject();

createStore({
  ...signUp,
  ...ipAddress
})

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => (
  // <StateMachineProvider><SimpleReactLightbox>{element}</SimpleReactLightbox></StateMachineProvider>
  <StateMachineProvider>{element}</StateMachineProvider>
)

export const onServiceWorkerUpdateReady = () => {
	alert('There is a new version available, please bear with us whilst we refresh...')
	window.location.reload();

}