import { useStaticQuery, graphql } from "gatsby"
export const useSiteMenuData = () => {
  const { wordpress } = useStaticQuery(
    graphql`
      query MenuData {
        wordpress {
          loadedSettings {
            loadedOptions {
              siteMessage
              showSiteMessage
            }
          }
					menus {
						nodes {
							name
							id
							slug
							menuItems(first: 100) {
								nodes {
                  path
                  id
                  label
                  parentId
                  childItems {
                    nodes {
                      id
                    }
                  }
									cssClasses
									url
									target
								}
							}
						}
					}
          # menus {
          #   nodes {
          #     id
          #     name
          #     slug
          #     menuItems(first: 100) {
          #       nodes {
          #         path
          #         id
          #         label
          #         parentId
          #         childItems {
          #           nodes {
          #             id
          #           }
          #         }
          #       }
          #     }
          #   }
          # }
          socialFields {
            facebook
            instagram
            linkedin
            pinterest
            twitter
            youtube
          }
        }
      }
    `
  )
  return wordpress
}
