import styled from "styled-components"
import {device, size} from "../theme/MediaQueries"
import ThemeDefault from "../theme/ThemeDefault";


const HeaderComponent = styled.header`

  align-items: flex-start;
  box-sizing: inherit;
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 0px;
  width: 100%;
  z-index: 9; 

  .header__message {
    padding: 8px;
  }

  .site__message {
    padding: 8px;
    width: 100%;
    color: ${ThemeDefault.white};
    background: ${ThemeDefault.black};

    p {
      padding: 0;
      margin: 0;
    }
  }

  .main__navigation {
    background-repeat: repeat;
    background-size: cover;
    box-sizing: inherit;
    display: block;
    height: auto;
    position: relative;
    transition: all 450ms ease 0s;
    width: 100%;
    z-index: 15; 
    padding: 0;
    padding-bottom: 8px;

		&.open {
			min-height: 100vh;
		}

    @media ${device.MXmd} {
      padding-left: 16px;
      padding-right: 16px;
    } 
    @media ${device.MXxs} {
      padding-left: 8px;
      padding-right: 8px; 
    }

    :before {
      border-bottom: 1px solid #161414;
      content: "";
      display: none !important;
      left: 50%;
      max-width:  ${size.lg};
      position: absolute;
      top: -4px;
      transform: translate(-50%);
      width: calc(100% - 48px);
      z-index: 2; 
			opacity: 1 !important;
    }

    :after {
      border-bottom: 1px solid #161414;
      border-top-style: none;
      bottom: -4px;
      content: "";
      left: 50%;
      max-width:  ${size.lg};
      position: absolute;
      transform: translate(-50%);
      width: calc(100% - 48px );
			opacity: 1 !important;
    }

    .header__inner {
      transition: 450ms;
      padding: ${ThemeDefault.responsivePadding};
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &.fixed {
    padding-bottom: 66px;
    
    .main__navigation {
      position: fixed;
      background-color: rgba(255, 255, 255, .7);

      &:after {
        bottom: 0;
      }
      
      .header__inner {
        padding: 0 16px;
      }

    }
    @supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
      .main__navigation {
        background-color: rgba(255, 255, 255, .7);
        -webkit-backdrop-filter: blur(0.3em);
        backdrop-filter: blur(0.3em);


      }
    }
  }


  .header__inner {
    align-items: center;
    box-sizing: inherit;
    display: flex;
    flex-flow: row nowrap;
    margin-top: 16px;
    width: 100%;
    z-index: 9; 
    
    @media ${device.MXmd} {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .header__logo {
    text-align: left;
    flex-direction: row;
    display: flex;
    height: 50px;
    max-width: 100%;
    text-align: left;
    vertical-align: top;
    width: auto;
    width: 290px;

    @media ${device.MXsm} {
      width: auto;
    }    
  }

  .header__logoDial {
    box-sizing: inherit;
    cursor: pointer;
    display: block;
    height: 50px;
    max-width: 100%;
    text-align: left;
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
    /* vertical-align: top; */
    height: 50px;
    
    &:hover {
      animation: 0s ease 0s 1 normal none running none;
      transform: rotate(360deg); 
    }
  }

  .header__logoText {
    height: 50px;
    @media (max-width: 991px) {
      max-width: 30%; 
    }
    @media (max-width: 479px) {
      margin-left: 0px;
      max-width: 40%; 
    }
    @media ${device.MXsm} {
      display: none;
    }
  }
  
  .header__menu {
  
    display: flex;
    justify-content: center;

    @media ${device.MXmd} {
      width: 100%;
      padding: 24px;
      display: block;
      /* overflow: scroll;
      height: 150vh;
      height: 100vh; */
    }
  
    & ul {
      display: flex;
      padding: 0;
      margin: 0;
      align-items: center;
      flex-direction: row;

      @media ${device.MXmd} {
        align-items: center;
        flex-direction: column; 
      }
    }
  }
  .menu__wrapper {
    align-items: center;
    box-sizing: inherit;
    display: flex;
    flex-direction: column;
    flex-flow: column nowrap;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: center;
    margin-top: 0px;
    padding-bottom: 0px;
    text-align: center;
    width: 80%; 

    @media ${device.MXmd} {
      position: fixed;
      width: 100%;
      height: 100vh;
      height: -webkit-fill-available;
      display: flex;
      background: ${ThemeDefault.offWhite};
      color: ${ThemeDefault.white};
      padding: 0;
      left: 0;
      top: 0;
      transform: translateX(-100%);
      align-items: center;
      justify-content: center;
      flex-direction: column; 
      transition: 400ms;
      opacity: 0;
      overflow: scroll;
    }

    &.menu__wrapper--show {
      transform: none;
      opacity: 1;
      z-index: 9;
      display: block;
    }
    
  }

  .close-button {
    position: fixed;
    top: 16px;
    right: 16px;
    display: inline-block;
    width: 36px;
  }

  .mobile {
    display: none;
    @media ${device.MXmd} {
      display: block;
    }
  }

  .cta__wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
    width: 290px; 
    
    @media ${device.MXmd} {
      width: auto;
    }

    .menu-button {
      display: none;
      cursor: pointer;
      
      @media ${device.MXmd} {
        display: inline-block;
        width: 36px;
        margin-left: 8px;
      }
    }
  }

  .mainNav__link {
    align-items: center;
    background-color: transparent;
    border-color: currentcolor;
    border-style: solid;
    border-width: 0px;
    box-sizing: inherit;
    cursor: pointer;
    display: inline-flex;
    font-weight: 700;
    justify-content: center;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    transition-property: background-color, color, border-color;
    transition-timing-function: ease-in-out;
    white-space: nowrap;
    width: 100%; 
    position: relative;

    @media ${device.MXmd} {
      font-size: ${ThemeDefault.font_3};
      padding: 0 0 8px 0;
      text-align: left;
      justify-content: flex-start;


      display: flex;
      flex-direction: column;
      align-items: flex-start;

    }

    & .subMenu {
      position: absolute;
      padding: 16px;
      bottom: 0;
      /* left: 50%;
      transform: translate(-50%, 100%); */
      left: 0;
      transform: translate(0, 100%);
      align-items: center;
      box-sizing: inherit;
      display: flex;
      flex-direction: column;
      flex-flow: column nowrap;
      flex-grow: 1;
      flex-shrink: 1;
      justify-content: center;
      margin-top: 0px;
      text-align: center;
      background-color: ${ThemeDefault.white};
      z-index: 2;
      display: none;
      opacity: 0;
      transition: opacity 300ms ease-in-out;
      transition-delay: 100ms;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);

      @media ${device.MXmd} {
        display: flex;
        position: relative;
        opacity: 1;
        transform: none;
        width: 100%;

        font-size: 0.8rem;
        padding: 0;
        padding-left: 8px;
        margin-bottom: 16px;
        border-block: none;
        background: none;
        box-shadow: none;
      }


      & .mainNav__link { 
        width: 100%;
        justify-content: flex-start;
        padding: 0;

        @media ${device.MXmd} {
          font-size: 1.6rem;
        }

        & a {
          font-weight: 400;
          position: relative;

          & :hover {
            :before {
							opacity: 1 !important;
              width: 100% !important;
            }
          }
        }

      }
      & .subMenu {
        top: 0;
        right: 0;
        left: auto;
        bottom: auto;
        transform: translate(100%, -16px)
      }
    }

    &:hover > .subMenu {
      display: flex;
      opacity: 1;
    }

    & a {
      color: ${ThemeDefault.black};
      @media ${device.MXmd} {
        width: 100%;
      }
    }
    
    @media ${device.MXmd} {
      color: ${ThemeDefault.white};
      text-align: center;
      justify-content: center;
    }
    
    & a {
      position: relative;
      
      @media ${device.MXmd} {
				width: calc(100% - 50px);
        text-align: left;
      }

      :before {
        transform: translate(-50%,-50%);
        height: 5px;
        position: absolute;
        left: 50%;
        top: 50%;
        background-color: #fae053;
        transition-duration: 0.45s;
        transition-timing-function: cubic-bezier(.87,.14,.31,.97);
        width: 0;
        z-index: -1;
        content: "";
      }
      :hover {
        color: #5c8eff;
        text-decoration: none; 

        :before {
          width: 100%;
					display: inline-block;
					opacity: 1 !important;

          @media ${device.MXmd} {
            width: 0;
            display: none;
          }
        }
        
      }
    }
  }

`

export default HeaderComponent