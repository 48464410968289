export const updateUserDetails = (state, payload) => {
  //console.log("state:", state);
  //console.log("payload:", payload);
  return {
    ...state,
    userDetails: {
      ...state.userDetails,
      ...payload,
    }
  };
}