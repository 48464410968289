
import React, { useState } from "react"

import styled from "styled-components";
import ThemeDefault from "../theme/ThemeDefault";

import {device} from "../theme/MediaQueries"

const LoadedFormButton = ({ children, cssClass, onClick, btnColor = 'teal', labelColor, disabled, type, style, ...props }) => {

  const [ hover, setHover	] = useState(false);

  const toggleHover = () => { setHover(!hover); };

  return (
    <button
      {...props} 
      onMouseEnter={toggleHover}
			onMouseLeave={toggleHover}
      onClick={ !disabled ? onClick : () => {}}
    >
      {children}
    </button>
  )
}

export default styled(LoadedFormButton).attrs(props => ({
  className: props.cssClass,
}))`
  background-color: ${ThemeDefault.black};
  color: ${ThemeDefault.white};
  border-color: ${ThemeDefault.black};
  border-radius: 0px;
  border-style: solid;
  border-width: 1px;
  box-sizing: inherit;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  line-height: 1;
  padding: 10px 16px;
  text-decoration: none !important;
  transition-duration: 0.4s;

  :hover {
    text-decoration: none !important;
    background-color: ${ThemeDefault.blackHover};
    color: ${ThemeDefault.white};
    border-color: ${ThemeDefault.blackHover};
    border-style: solid;
    border-width: 1px;
  }

  &.blue {
    background-color: ${ThemeDefault.blue};
    border-color: ${ThemeDefault.blue};

    :hover {
      color: ${ThemeDefault.white};
      background-color: ${ThemeDefault.blueDark};
      border-color: ${ThemeDefault.blueDark};
    }
  }

  &.open {
    background-color: ${ThemeDefault.white};
    color: ${ThemeDefault.black};
    border-color: ${ThemeDefault.black};
    
    :hover {
      color: ${ThemeDefault.white};
      background-color: ${ThemeDefault.blackHover};
      color: ${ThemeDefault.white};
      border-color: ${ThemeDefault.blackHover};      
    }
  }

  &.open.blue {
    background-color: ${ThemeDefault.white};
    border-color: ${ThemeDefault.blue};
    color: ${ThemeDefault.blue};

    :hover {
      color: ${ThemeDefault.white};
      background-color: ${ThemeDefault.blueDark};
    }
  }

  &.text {
    background-color: transparent;
    color: ${ThemeDefault.black};
    border-color: transparent;
    position: relative;
    transition: 400ms;

    :before {
      content: '';
      position: absolute;
      height: 1px;
      width: calc(100% - ((32px / 4) * 3));
      background-color: currentColor;
      bottom: 4px;
      left: 50%;
      transform: translateX(-50%);
      transition: all 400ms ease-in-out 0s, bottom 100ms ease-in-out 0s, width 200ms ease-in-out 100ms, height 400ms ease-in-out 300ms;
      z-index: -1;
    }

    :hover {
      color: ${ThemeDefault.white};;
      background-color: transparent;
      border-color: transparent;    

      :before {  
        bottom: 0;
        height: 100%;
        width: 100%;
        background-color: ${ThemeDefault.blue};
      }
    }
  }

  &.text.blue {
    background-color: ${ThemeDefault.white};
    border-color: ${ThemeDefault.blue};
    color: ${ThemeDefault.blue};

    :hover {
      color: ${ThemeDefault.white};
      background-color: ${ThemeDefault.blueDark};
    }
  }

  &.hero {
    font-weight: 400;
    line-height: 1;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 24px;
    padding-right: 24px;
    margin-right: 16px;

    @media ${device.MXmd} {
      /* padding: 8px 16px; */
    }
  }
`