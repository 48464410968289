import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import { useRelative } from "../hooks/useRelative";
import { useSiteMenuData } from "../hooks/useSiteMenuData";

import HeaderComponent from './HeaderStyles'

import LogoDial from "../../assets/images/logo/logo-dial-1.svg"
import Logo from "../../assets/images/logo/logo-text-alt.svg"
// import IconBars from "../../assets/images/icons/bars.svg"
// import IconTimes from "../../assets/images/icons/times.svg"
import LoadedButton from "../elements/LoadedButton";

import MenuBars from "../../assets/images/icons/menu-bars.svg";
import MenuClose from "../../assets/images/icons/menu-cross.svg";
import ParseHTML from "../helpers/ParseHTML";

const Header = () => {

  const [menuOpen, toggleMenu] = useState(false)
  const menuData = useSiteMenuData() 
  const headerData = menuData.menus.nodes.find(menu => menu.slug === "main-menu")

  const [isSticky, setSticky] = useState(false);
  const [degrees, setDegrees] = useState(0);

  const ref = useRef(null);


  const handleScroll = () => {

    setDegrees(window.pageYOffset / 2);

    if (ref.current) {
      setSticky(window.pageYOffset >= ref.current.clientHeight);
    }else{
      setSticky(window.pageYOffset >= 5);
    }
  };

  const handleKeyDown = (ev) => {
    // check keys if you want

    if (window.outerWidth <= 991) {
      if (ev.keyCode === 13) {
        if (menuOpen) {
          document.body.classList.remove('menu-open');
        }else{
          document.body.classList.add('menu-open');
        }
        toggleMenu(!menuOpen)
      }
    }
  }

  const handleMenuOpen = () => {
    if (window.outerWidth <= 991) { 
      if (menuOpen) {
        document.body.classList.remove('menu-open');
      }else{
        document.body.classList.add('menu-open');
      }
      toggleMenu(!menuOpen)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };

  }, []);

  //console.log(menuData.loadedSettings.loadedOptions.showSiteMessage);
  //console.log(menuData.loadedSettings.loadedOptions.siteMessage);

  return (
    <HeaderComponent className={`main-header-nav sticky-wrapper${isSticky ? ' fixed' : ''}`} >
      {menuData.loadedSettings.loadedOptions.showSiteMessage && <section className="site__message" ref={ref}><div className="inner-div header__message"><div className="message">{ menuData.loadedSettings.loadedOptions.siteMessage && ParseHTML(menuData.loadedSettings.loadedOptions.siteMessage) }</div></div></section> }
      <section className={`main__navigation ${menuOpen ? 'open' : 'closed'}`}>
        <div className="inner-div header__inner">
          <Link to="/" className="header__logo">
            <DialWrapper className="dialWrapper" deg={degrees}><LogoDial className="header__logoDial"/></DialWrapper>
            <Logo className="header__logoText" />
          </Link>
          <div role="button" tabIndex={-99} className={menuOpen ? `menu__wrapper menu__wrapper--show` : `menu__wrapper`} onClick={handleMenuOpen} onKeyDown={handleKeyDown}>
            <nav className={menuOpen ? `header__menu header__menu--show` : `header__menu`}>
              <ul>
                {headerData.menuItems.nodes.map((item, index) => {
                    return (item.parentId === null && <MenuItem role="button" tabIndex={index} key={`header-nav-${index}`} headerData={headerData} {...item} onClick={handleMenuOpen} onKeyDown={handleKeyDown}/>)}
                )}
              </ul>
            </nav>
            <div className="mobile close-button">
              <MenuClose />
            </div>
          </div>
          <div className="cta__wrapper">
            <LoadedButton role="button" tabIndex={-10} cssClass="text" to="/login">Login</LoadedButton>
            <LoadedButton role="button" tabIndex={-10} cssClass="open" to="/sign-up">Free Trial</LoadedButton>
            <div role="button" tabIndex={-90}  className="menu-button" onClick={handleMenuOpen} onKeyDown={handleKeyDown}>
              <MenuBars />
            </div>
          </div>
        </div>
      </section>
    </HeaderComponent>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

const MenuItem = (props) => {

  const { label, path, onClick, childItems, headerData, id } = props

  const relativeLink = useRelative(path)
  // console.log('url', path, relativeLink);
  return (
    <li className={`mainNav__link ${props.childItems.nodes.length > 0 && "has-dropdown"}`}>

      {relativeLink.indexOf('#') >= 0 ?
        <AnchorLink to={relativeLink} onClick={onClick} className="hash-link" stripHash>{label}</AnchorLink> :
				(relativeLink.indexOf('http') === -1) ? <Link to={relativeLink} onClick={onClick} >{label}</Link> : <a href={relativeLink} >{label}</a>
      }

      { props.childItems.nodes.length > 0 && <SubMenu childItems={childItems} parentID={id} headerData={headerData} /> }
    </li>
  )
}

const SubMenu = ( props ) => {

  //console.log(props)
  const SubMenu = props.headerData.menuItems.nodes.filter(menuItem => menuItem.parentId === props.parentID)
  //console.log(SubMenu)
  return (
    <ul className="subMenu">
      {SubMenu.map((item, index) => {
          return (item.parentId !== null && <MenuItem role="button" tabIndex={index} key={`sub-${props.parentID}-header-nav-${index}`} {...item} onClick={props.onClick} onKeyDown={props.onKeyDown} headerData={props.headerData}/>)}
      )}
    </ul>
  )
}

const DialWrapper = (props) => {

  // console.log(props.deg);
 
  return <div style={{transform: `rotate(${props.deg}deg)`}}>{props.children}</div>
}

export default Header
