import styled from "styled-components";
import { device, size } from "../../components/theme/MediaQueries";
import ThemeDefault from "../theme/ThemeDefault";

    
const FooterComponent = styled.footer.attrs(props => ({
  className: props.cssClass,
}))`
	position: relative;
	z-index: 10;
	color: ${props => props.theme.black};
  margin-bottom: 16px;
  width: 100%;

	& .mh-100 {
		min-height: 100%;
	
		@media ${device.MXmd} {
			min-height: auto;
		}
	}

	.footer__contents {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin: 0 auto;
		max-width: ${size.lg};
		width: 100%;
		padding: ${ThemeDefault.responsivePadding};
		padding-bottom: 8px;
		padding-top: 8px;

		@media ${device.md} {
			flex-direction: column;
		}
	}

	a {
		align-items: center;
		display: flex;
		justify-content: center;

		color: ${props => props.theme.black};
		font-weight: 400;
		text-decoration: none;
		transition: 0.2s all ease;


		svg {
			margin-right: 8px;
		}
	}

  & .copyright {
    padding: 0 0 8px 0;
		margin-top: 24px;
		
    @media ${device.MXmd} {
			margin-top: 16px;
			text-align: center !important;
			flex-direction: column !important;
			display: flex !important;
    }

    & div {
      text-align: left;
      
      @media ${device.MXmd} {
        text-align: center;
      }
    }
  }

  & .site-credit {
    display: block;
    width: 100%;
		text-align: right !important;

		@media ${device.MXmd} {
			text-align: center !important;
		}
    & a {
      justify-content: flex-start;
      color: ${ThemeDefault.black};
      font-size: ${ThemeDefault.font__2};
      display: inline-block;
      width: auto;
      position: relative;

      :before {
        transform: translate(-50%,-50%);
        height: calc(80% - 8px);
        position: absolute;
        left: 50%;
        top: 50%;
        background-color: #fae053;
        transition-duration: 0.45s;
        transition-timing-function: cubic-bezier(.87,.14,.31,.97);
        width: 0;
        z-index: -1;
        content: "";
      }
      :hover {
        color: ${ThemeDefault.black};
        text-decoration: none; 

        :before {
          width: calc(100% + 8px);
        }
      }
    }
  }

	nav {
    display: flex;
    flex-direction: column;
		justify-content: center;
    align-items: flex-start;
	}

	.footer-menu {
		margin-bottom: 16px;
	}

	.footer-submit {
		/* width: 100% !important; */
	}

	

	.footerNav__link {
    align-items: center;
    background-color: transparent;
    border-color: currentcolor;
    border-style: solid;
    border-width: 0px;
    box-sizing: inherit;
    color: #161414;
    cursor: pointer;
    display: inline-flex;
    justify-content: flex-start;
    padding: 0;
    text-align: left;
    text-decoration: none;
    transition-property: background-color, color, border-color;
    transition-timing-function: ease-in-out;
    white-space: nowrap;
    width: auto; 
    position: relative;

		&.strong {
			font-weight: 700
		}

    :before {
      transform: translate(-50%,-50%);
      height: calc(50% - 8px);
      position: absolute;
      left: 50%;
      top: 50%;
      background-color: #fae053;
      transition-duration: 0.45s;
      transition-timing-function: cubic-bezier(.87,.14,.31,.97);
      width: 0;
      z-index: -1;
      content: "";
    } 
    :hover {
      color: #5c8eff;
      text-decoration: none; 

      :before {
        width: calc(100%);
      }
    }
  }
  .socials {
    font-weight: 400;
    padding: 8px 0;
  }

  .socialWrapper {
    display: flex;
    flex-direction: row;

    a {
      cursor: pointer;
      
      & svg {
        transition: 400ms;
      }
      
      &:hover svg {
        transform: rotate(360deg);
      }
    }
  } 

	& .my-masonry-grid {
		display: -webkit-box; /* Not needed if autoprefixing */
		display: -ms-flexbox; /* Not needed if autoprefixing */
		display: flex;
		/* margin-left: -30px; gutter size offset */
		width: auto;
	}

	& .disclaimer {
		margin-top: 16px;
		font-size: 1.4rem;
		font-style: italic;	
	}
`;

export default FooterComponent;
